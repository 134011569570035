<template>
    <div>
    

	 <CajasComponent 
	 		moduloOrigen = "pos" 
            :usuarioId = "user.user_ligin_id" 
            />    
        </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import CajasComponent  from '../contabilidad/CajasComponent'

    export default {
        name:'CajasUsuarioComponent',
        components:{
         	CajasComponent            
        },
        computed: {        	              
        	
        	...mapState('master',['user','tenantId']),
        	...mapState('pos',['menu'])
        },
        mounted() {
        	console.log(this.user, this.tenantId)
        }
    }
</script>

<style>

</style>