<template>
    <div>


        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-toolbar flat color="white">
            <v-toolbar-title>Cajas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical>
            </v-divider>
            <v-spacer></v-spacer>
            <v-text-field v-model="cajaNum" append-icon="mdi-magnify" label="Numero" single-line id="searchNum" class='mt-3'
                @keydown.enter=cargarListaCajas(true) @click:append=cargarListaCajas(true)></v-text-field>
            <v-text-field v-model="cajaFecha" append-icon="mdi-magnify" label="Fecha" single-line id="searcFecha"
                class='mt-3' @click:append=cargarListaCajas(true)></v-text-field>
            <v-select clearable v-model="cajaEstado" append-icon="mdi-magnify" label="Estado" :items="estadosCaja"
                item-text="estado_nombre" item-value="estado_id" class='mt-3' @click:append=cargarListaCajas(true)
                @change=cargarListaCajas(true)>
            </v-select>

            <v-text-field v-model="cajaUsuario" append-icon="mdi-magnify" label="Usuario" single-line id="searchUsuario"
                class='mt-3' @click:append=cargarListaCajas(true) @keydown.enter=cargarListaCajas(true)></v-text-field>
        </v-toolbar>

        <v-data-table :items="dtCajas" :headers="headers" :options.sync="options" :loading="loadingTable"
            loading-text="Cargando registros..." hide-default-footer class="elevation-1" dense :itemsPerPage="itemsPerPage">

            <template v-slot:item.actions="{ item }">
                <v-btn icon color='red' target="_blank" :to="`/ver-caja/${item.caja_id}`">

                    <v-icon color='orange'>mdi-table-edit</v-icon>
                </v-btn>


            </template>
            <template v-slot:item.efe="{ item }">
                {{ item.efe }}
                <v-icon v-if="item.efe != null && item.efe_dep != null && !conciliarCaja" color="green">mdi-check</v-icon>

                <v-icon v-if="item.efe != null && (item.efe_dep == null) && !conciliarCaja" color="red">mdi-cancel</v-icon>


                <v-icon v-if="item.efe_dep_conc != null && conciliarCaja" color="green">mdi-check</v-icon>
            </template>

            <template v-slot:item.chq="{ item }">

                {{ item.chq }}

                <v-icon v-if="item.chq_dep != null && !conciliarCaja" color="green">mdi-check</v-icon>

                <v-icon v-if="item.chq != null && item.chq_dep == null && !conciliarCaja" color="red">mdi-cancel</v-icon>


                <v-icon v-if="item.chq_dep != null && conciliarCaja" color="green">mdi-check</v-icon>


            </template>

            <template v-slot:footer>
                <v-pagination class='mt-10' v-model=currentPage :length=pageCount @input="handlePageChange"
                    total-visible="15"></v-pagination>
            </template>

        </v-data-table>
    </div>
</template>


<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'


export default {
    name: 'CajasComponent',
    props: {
        usuarioId: {
            type: String,
            default: null
        },
        moduloOrigen: {
            type: String,
            default: "contabilidad"
        }

    },
    components: {

    },
    data: () => ({
        cajaNum: "",
        cajaFecha: "",
        cajaEstado: "",
        cajaUsuario: "",
        headers: [
            { text: 'Opciones', align: 'start', value: 'actions', align: 'center', width: 50 },
            { text: 'Numero', align: 'start', value: 'caja_id', width: 60 },
            { text: 'Fecha', align: 'start', value: 'fecha', width: 70 },
            { text: 'Estado', align: 'start', value: 'estado_actual', width: 80 },

            { text: 'Usuario', align: 'start', value: 'user_id', width: 70 },
            { text: 'Efectivo', align: 'end', value: 'efe', width: 80 },

            { text: 'Cheque', align: 'end', value: 'chq', width: 80 },



        ],
        estadosCaja: [{ estado_id: 'CJ_ABIERTA', estado_nombre: 'ABIERTA' }, { estado_id: 'CJ_CERRADA', estado_nombre: 'CERRADA' }],
        dtCajas: [],
        options: {},
        overlay: false,
        search: "",
        dialogIngEfe: false,
        headersTransfItems: [],
        uid: "",
        datosDepEfe: [],

        formIngEfe: [],
        formIngChq: [],


        pageCount: 1,
        currentPage: 1,
        itemsPerPage: 20,


    }),
    computed: {
        ...mapState('master', ['loadingTable']),
        ...mapState('contabilidad', ['menu']),
        ...mapState('pos', ['menuPos']),
        ...mapGetters('access', ['conciliarCaja'])

    }
    , methods: {
        ...mapMutations('master', ['setUrl', 'setOverlay', 'setMenu', 'setLoadingTable']),

        ...mapActions('master', ['requestApi']),

        cargarListaCajas(filtrar) {
            this.dtCajas = []


            this.setLoadingTable(true)
            if (filtrar == true) {
                this.currentPage = 1;
            }
            //this.setUrl('cajas'+'?usuario=' + this.usuarioId)
            this.setUrl('cajas')

            let ux = this.cajaUsuario
            if (this.usuarioId == null) {
                ux = this.cajaUsuario
            } else {
                ux = this.usuarioId

            }


            //paramData = {"usuario": this.usuarioId}

            this.requestApi({
                method: 'GET',
                data: {
                    usuario: this.usuarioId,
                    page_size: this.itemsPerPage,
                    page: this.currentPage,
                    caja_id: this.cajaNum,
                    fecha: this.cajaFecha,
                    estado: this.cajaEstado,
                    usuario: ux
                }
            }).then(res => {
                let data = res.data._embedded.cajas
                this.dtCajas = data
                this.pageCount = res.data.page_count
                this.setLoadingTable(false)

            })
        },

        nuevaCaja() {
            this.dialogDepEfe = true

        }

        ,
        guardarCaja() {

        }
        ,
        handlePageChange(value) {
            this.currentPage = value
            this.cargarListaCajas(false)
        },
    },

    watch: {

        options: {
            handler() {
                this.cargarListaCajas(true)
            },
            deep: true,
        },
    },
    mounted() {

        if (this.moduloOrigen == "contabilidad") {
            //this.setMenu(this.menu)
        }

        if (this.moduloOrigen == "pos") {
            //this.setMenu(this.menuPos)
        }



    },



}
</script>